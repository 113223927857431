import './SignIn.css';


export default function BackgroundVideo() {
  return (
      <video className="video" autoPlay muted loop id="myVideo">
          <source src="https://ic-index.com/bglandingpg-_final.mp4" type="video/mp4"/>
      </video>
  )


}