import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SaveIcon from '@mui/icons-material/Save';
import { QuestionnaireInitiative } from '@icindex/v3-firebase';

export type AnalysisEditorProps = {
    initiative: QuestionnaireInitiative;
    callback: (value?: string) => void;
}


export default function AnalysisEditor(props:AnalysisEditorProps) {
  const [value, setValue] = useState('');

  const handleSave = ()=>{
    if(props.callback)
        props.callback(value);
  }

  useEffect(()=>{
    if(props.initiative.quantitativeAnalysis){
        if(Array.isArray(props.initiative.quantitativeAnalysis))
            setValue(props.initiative.quantitativeAnalysis.join('\n'));
        else
            setValue(props.initiative.quantitativeAnalysis)
    }
  },[])

  return (
    <div className="flex">
        <div className="w-11/12">
            <ReactQuill theme="snow" value={value} onChange={setValue} />
        </div>
        <div className="w-1/12 flex flex-row">
            <div className="w-full flex place-content-center">
                <IconButton aria-label="save" size="large" onClick={handleSave}>
                    <SaveIcon fontSize="large" />
                </IconButton>
            </div>
        </div>
    </div>
  );
  
}