import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { addListener, ANY_CHANGE, QuestionnaireType } from '@icindex/v3-firebase';
import { Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { QuestionnaireStateTypes } from '../context/QuestionnaireState';
import { useTranslation } from 'react-i18next';

function QuestionnaireView() {
    const { state,dispatch } = useContext(AppContext);

    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const {id} = useParams();
    const {t} = useTranslation();

    useEffect(()=>{
        if(id)
            setValue(parseInt(id));
    },[location]);

    useEffect(()=>{
        if(value === 0){
            handleChange(null,0)            
        }

        addListener(ANY_CHANGE,(correlation:number) => {
            dispatch({type:QuestionnaireStateTypes.REFRESH})
        })
    },[]);

    const handleChange = (event: React.SyntheticEvent|null, newValue: number) => {
      setValue(newValue);
      if(newValue === 0){
        navigate('/dashboard')
      }
      else {
          navigate(`/type/${newValue}`);
      }
    };
  
    var color = state.theme.detail?.type?.SelectedTabCaption || state.theme.primary

    const sxClass:any = {
        "& button.Mui-selected":{color:`${color} !important`}
    }

    if(state.theme.detail?.type?.TabCaption){
        sxClass["& button.MuiTab-textColorPrimary"]= {color:state.theme.detail?.type?.TabCaption}
    }

    const types = state.questionnaire!.panel.Types().filter(x => x.id !== 'powerBI' && (
        state.questionnaire!.panel.CurrentUnit() === undefined || state.questionnaire!.panel.TypeWeight(x) > 0
    )
    );


    return (
            <div className={`w-full ${state.theme.background}-400 py-1 rounded-lg px-3`}>
                <Tabs
                    onChange={handleChange}
                    value={value}
                    sx={sxClass}
                    TabIndicatorProps={{
                        sx:{
                            backgroundColor: color
                            
                        }
                    }}
                >
                    <Tab label={t('terms.dashboard')}  />
                    {
                        types.map((type:QuestionnaireType) => (
                            <Tab label = {type.title} key={type.id} />
                        ))
                    }
                    {state.questionnaire!.powerBiInitiatives.length >0?(<Tab label="Power BI" value={-1}/>):null}
                </Tabs>
            </div>
    );
}

export default QuestionnaireView;
