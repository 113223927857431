import NumberFormat from 'react-number-format';

export type PercentFormatProps = {
    value: number;
    decimals?: number;
}

export function PercentFormat(props: PercentFormatProps) {
    return (
        <NumberFormat value={props.value * 100} displayType={'text'} fixedDecimalScale={true} decimalScale={props.decimals || 1} suffix='%' />
    )
}