import { QuestionnaireCollector, QuestionnaireData, QuestionnaireInitiative } from "@icindex/v3-firebase";
import { Tooltip, IconButton } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { PercentFormat } from "../../components/PercentFormat";
import { AppContext } from "../../context/AppContext";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Database from "@icindex/v3-firebase/dist/Database";

export type DataTableProps = {
    initiative: QuestionnaireInitiative,
    month?: number
    onDelete?: (data:QuestionnaireData) => void,
    onEdit?:(data:QuestionnaireData) => void,
    onAdd?:()=>void
  }
  
function FirstColHeader(period:string) {
  if(period === "year") return null;
  
  const label:any = {
    month: "Data",
    quarter: "Trimestre",
    semester: "Semestre"
  }

  return (
    <th className='font-bold border border-grey-300 p-4'>
      {label[period]}
    </th>
  )
}
  
function FirstColValue(period:string, data:QuestionnaireData) {
  if(period === "year") return null;

  const label:any = {
    month: data.date,
    quarter: data.quarter,
    semester: data.semester
  }

  return (
    <td className='text-black p-4 border border-grey-300 text-lg'>
      <span>
        {label[period]}
      </span>    
    </td>
  )
}
export default function DataTable(props:DataTableProps) {
  const {state,dispatch} = useContext(AppContext);
  const relation = state.questionnaire?.panel.Relation(props.initiative)!
  const period = state.questionnaire!.panel.InitiativePeriod(props.initiative)
  const data = period === "month" ? relation[props.month!] : relation.flat();
  const [addDataEnabled,setAddDataEnabled] = useState(false);

  const handleEdit = (evt: any, dataElement: QuestionnaireData) => {
    if(props.onEdit) {
      props.onEdit(dataElement);
    }
  }

  const handleDelete = (evt:any, dataElement:QuestionnaireData) => {
    if(props.onDelete) {
      props.onDelete(dataElement);
    }
  }

  useEffect(()=>{
      const aux = !(state.questionnaire?.panel.Units() !== undefined && state.questionnaire?.panel.CurrentUnit() == undefined);
      setAddDataEnabled(aux && !state.questionnaire!.panel.isReadOnly())
  },[state]);


  return (
        <table className='table-auto whitespace-nowrap border-collapse '>
          <thead>
            <tr className={`text-grey-600 text-lg font-bold ${state.theme.background}-200`}>
              <th className='font-bold border border-grey-300 p-4'>
                <Tooltip title='Adicionar anotação'>
                  <IconButton size='small' onClick={props.onAdd} disabled={!addDataEnabled}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </th>
              {FirstColHeader(period)}

              {props.initiative.collectors.map((el: QuestionnaireCollector) => (
                <th key={el.id} className='font-bold border border-grey-300 p-4'> {el.label} </th>
              ))
              }
            </tr>
          </thead>

          <tbody className='divide-grey-300 divide-y border border-grey-300'>
            {
              data.map((dataElement: QuestionnaireData) => {
                Database.getLogger().silly({
                  type:'DataTable',
                  value:{log:"DataTable row render",dataElement}
                });
                const dataRow = props.initiative.collectors.map((collector: QuestionnaireCollector) => {
                  let value = dataElement[collector.id];
                  if(collector.input.type === 'list' && typeof collector.input.values[0] !== 'string') {
                    value = (collector.input.values as {label:string,value:string|number}[]).find(x => x.value === value)!.label;
                  }

                  const auxElement = collector.input.type === 'percentage'
                    ? (<PercentFormat value={value as number} />)
                    : (<span>{value?.toString()}</span>);
                  
                  return (
                    <td key={collector.id} className='text-black p-4 border border-grey-300 text-lg'> 
                      {auxElement}
                    </td>
                  )
                });
                return (
                  <Fragment key={dataElement.docId}>
                    <tr className='even:bg-grey-100'>
                      <td>
                        <Tooltip title='Editar anotação'>
                          <IconButton size='small' onClick={(evt: any) => { handleEdit(evt, dataElement) }} disabled={state.questionnaire!.panel.isReadOnly()}>
                            <EditIcon fontSize='small'  />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Excluir anotação" >
                          <IconButton size='small' onClick={(evt: any) => { handleDelete(evt, dataElement) }} disabled={state.questionnaire!.panel.isReadOnly()}>
                            <DeleteIcon fontSize='small'  />
                          </IconButton>
                        </Tooltip>

                      </td>
                      {FirstColValue(period,dataElement)}
                      {dataRow}
                    </tr>
                  </Fragment>
                )
              })
            }
          </tbody>
        </table>
  )
}

