import { Button, Menu, MenuItem } from '@mui/material';
import { useContext, useState } from 'react';
import { AppContext } from '../context/AppContext';
import AvatarMenu from './AvatarMenu';
import UnitMenu from './UnitMenu';

function TopBar() {
    const { state } = useContext(AppContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className='flex w-full justify-center content-start'>
            <div className={`flex items-center w-3/5 ${state.theme.background}-300 rounded-lg mt-4 px-8`} style={{backgroundColor:state.theme.detail?.questionnaire?.CaptionBackground}}>
                <p className="text-black text-lg font-medium py-4">
                    {state.questionnaire!.panel.Title()}
                </p>
                <div className="grow" />
                <UnitMenu/>
                <AvatarMenu />
            </div>
        </div>
    );
}

export default TopBar;
