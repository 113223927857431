import { useContext } from 'react';
import GaugeChart from 'react-gauge-chart'
import { AppContext,AppType } from '../context/AppContext';


export function getColor(state:AppType,value:number) {
    const thresholds = state.questionnaire?.panel.Thresholds();
    let color = '#000';
    if(value > thresholds!.med)
        color = state.theme.grade.hi;
    else if(value > thresholds!.low)
        color = state.theme.grade.med;
    else
        color = state.theme.grade.low;

    return color
}

export type GaugeDisplayProps = {
    value: number;
}

export function GaugeDisplay(props: GaugeDisplayProps) {
    const { state, dispatch } = useContext(AppContext)

    const thresholds = state.questionnaire?.panel.Thresholds()!;
    const colors = [
        ...Array(Math.ceil(thresholds.low/0.05)).fill(state.theme.grade.low),
        ...Array(Math.ceil((thresholds.med-thresholds.low)/0.05)).fill(state.theme.grade.med),
        ...Array((Math.ceil((1-thresholds.med)/0.05))==0?1:(Math.ceil((1-thresholds.med)/0.05))).fill(state.theme.grade.hi),

    ]

    return (   
        <GaugeChart id="gauge-chart2"
            nrOfLevels={colors.length}
            colors={colors}
            percent={isNaN(props.value)?0:props.value}
            textColor={getColor(state,props.value)}
            className='flex-none font-bold' 
        />
    )
}

