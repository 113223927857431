import { QuestionnaireInitiative, QuestionnairePanel } from '@icindex/v3-firebase';

export function makeHeaderTitle(panel: QuestionnairePanel, initiative: QuestionnaireInitiative, idx: number, baseYear: number, t: (v: string) => string): string {
    const period = panel.InitiativePeriod(initiative);
    if (period === "month" || period === "quarter") {
        return `${t('initials.quarter')}${idx + 1}`;
    }
    else if (period === "semester") {
        return `S${idx + 1}`;
    }
    else {
        return baseYear + ``;
    }
}

export function makePeriodLabels(panel: QuestionnairePanel, initiative:QuestionnaireInitiative, t: (v: string,options?:any) => any[], year?:string|number): string[] {
    const period = panel.InitiativePeriod(initiative);
    const sufixYear = year ? `/${year.toString().substring(2,4)}` : '';

    if(period === "month") {
        return t('initials.months',{returnObjects:true}).map((el: string) => `${el}${sufixYear}`)
    }
    else if(period === "quarter") {
        const prefix = t('initials.quarter')
        return [1,2,3,4].map((el:number) => `${prefix}${el}`)
    }
    else if(period === "semester") {
        return ['S1','S2'];
    }
    else if(period === "year") {
        return [year!.toString()]
    }
    else throw `${period} not implemented`
    
}