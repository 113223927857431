import { QuestionnaireIndicator, QuestionnaireInitiative } from "@icindex/v3-firebase";
import { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { AppContext } from "../context/AppContext";
import Database from "@icindex/v3-firebase/dist/Database";

export type InitiativeValueTableProps = {
  initiative: QuestionnaireInitiative;
}

export function InitiativeValuesTable(props: InitiativeValueTableProps) {
  const {state} = useContext(AppContext)
  const bodyClass = 'border-grey-400 p-2 border-b border-r text-black'
  const footerClass = `${state.theme.background}-300 border-grey-400 p-2 border-b border-r text-black`
  const {t} = useTranslation()

  const period = state.questionnaire!.panel.InitiativePeriod(props.initiative)
  const header = HeaderTags(period,state.questionnaire!.panel.BaseYear().toString(),state.theme.background,t)


  const content = props.initiative.indicators.map((indicator: QuestionnaireIndicator) => {
    const values = state.questionnaire!.panel.Values(indicator);
    Database.getLogger().silly({
      type: "InitiativeValuesTable",
      value: {
        initiative: props.initiative,
        indicator: indicator,
        values
      }
    });
    return values.map((val: number,idx:number) =>
    (
      <div key={`${indicator.id}#${idx}`} className={bodyClass}> {ValueTag(val,indicator.display)} </div>
    ))
  });

  const totals = state.questionnaire!.panel.Totals(props.initiative).map((val:number,idx:number) => 
    (<div key={`${props.initiative.id}#${idx}`}className={footerClass}>{ValueTag(val,"percentage")}</div>)
  )

  return (
    <div className={`grid grid-cols-${header.cols} border-grey-400 rounded-md border`}>
      {header.elements}
      {content}
      {totals}
    </div>
  )
}

function ValueTag(val:number,display:string): ReactElement {
  
  if(isNaN(val)) {
    return (<span>N/A</span>);
  }
  
  if(display === "percentage") {
    return (
      <NumberFormat value={val * 100} displayType={'text'} fixedDecimalScale={true} decimalScale={1} suffix='%' />
    )
  }
  else {
    return (
      <NumberFormat value={val} displayType={'text'} fixedDecimalScale={true} decimalScale={1}  />
    )
  }
}

function HeaderTags(period:string,year:string,bgColor:string,t:(v:string,o:any)=>string[]): {elements: ReactElement[],cols:number} {
   const headerClass = `text-white ${bgColor}-600 p-2 font-bold text-base`

   if(period === "month") {
    const sufixYear = year.substring(2,4)
    return {
      elements: t('initials.months',{returnObjects:true}).map((el: string) => (
      <div key={el} className={headerClass}> {el.concat('/', sufixYear)} </div>
      )),
      cols: 12
    }
   }
   else if(period === "quarter") {
    return {
      elements: ['Q1','Q2','Q3','Q4'].map((el: string) => (
      <div key={el} className={headerClass}> {el} </div>
      )),
      cols: 4
    }
   }
   else if(period === "semester") {
    return {
      elements: ['S1','S2'].map((el: string) => (
      <div key={el} className={headerClass}> {el} </div>
      )),
      cols: 2
    }
   }
   else if(period === "year") {
    return {
      elements: [(<div key="year" className={headerClass}> {year} </div>)],
      cols: 1
    }
   }
   else throw `${period} not implemented`
}