import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import './SignIn.css';
import { Dispatch, SetStateAction, useState } from 'react';
import Database from '@icindex/v3-firebase/dist/Database';
import BackgroundVideo from './BackgroundVideo';


export default function SignIn() {
  const [email,setEmail] = useState<string>('')//('denes+icindexacao@denes.com.br');
  const [password,setPassword] = useState<string>('')//('Senha#001');

  const handleSignIn = () => {
    Database.authenticate(email,password);
  }

  const handleChange = (evt:any, setter:Dispatch<SetStateAction<string>>) => {
    evt.preventDefault()
    setter(evt.target.value);
  }

  return (
    <>
      <BackgroundVideo/>
      <div className="flex flex-col justify-center items-center h-screen">
          <div className="flex flex-col items-center w-1/4 bg-white h-fit p-8 opacity-80">
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" >
                Sign in
              </Typography>
              <TextField
                 margin="normal"
                 fullWidth
                 label="Email Address"
                 autoComplete="email"
                 autoFocus
                 value={email}
                 onChange={(evt:any)=>{handleChange(evt,setEmail)}}
              />              
             <TextField
               margin="normal"
               fullWidth
               label="Password"
               type="password"
               autoComplete="current-password"
               value={password}
               onChange={(evt:any)=>{handleChange(evt,setPassword)}}
               />
             <Button
               type="submit"
               fullWidth
               variant="contained"
               sx={{ mt: 3, mb: 2 }}
               onClick={handleSignIn}
             >
              Sign In
            </Button>
          </div>
        </div>
    </>
  )

}