import { UserInfo } from '@icindex/v3-firebase';
import Database from '@icindex/v3-firebase/dist/Database';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import TopBar from './components/TopBar';
import { AppContext } from './context/AppContext';
import { QuestionnaireStateTypes } from './context/QuestionnaireState';
import { ThemeStateTypes } from './context/ThemeState';
import QuestionnaireView from './questionnaire/QuestionnaireView';
import SelectQuestionnaire from './signin/SelectQuestionnaire';
import SignIn from './signin/SignIn';

function App() {

  const [authenticated, setAuthenticated] = useState(Database.isAuthenticated());
  const { state, dispatch } = useContext(AppContext)

  useEnhancedEffect(() => {
    Database.onAuthStateChanged(() => {
      setAuthenticated(Database.isAuthenticated());
      Database.getUserInfo().then((info:UserInfo|null)=>{
        if(info) {
          dispatch({type:ThemeStateTypes.SET, theme:info.theme! });
        }
      })
    })
  }, [])

  if (authenticated !== 'NOT_LOGGED') {
    if (state.questionnaire?.panel) {
      return (
        <div className="App">
          <header className={`App-header ${state.theme.background}-500`}>
            <div className='flex flex-col w-full space-y-4 px-2'>
              <TopBar/>
              <QuestionnaireView/>
              <Outlet/>
            </div>
          </header>

        </div>
      );
    }
    else {
      return (<SelectQuestionnaire />)
    }
  }
  else {
    return (<SignIn />);
  }

}

export default App;
