import { QuestionnaireInitiative, QuestionnaireType } from "@icindex/v3-firebase";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { PercentFormat } from "../components/PercentFormat";
import { InitiativeTable } from "./InitiativeTable";
import { ResultColoredValue } from "../components/TypeResult";
import { useTranslation } from "react-i18next";
import { DashboardStateTypes } from "../context/DashboardState";


function InitiativePanel() {
    const { state, dispatch } = useContext(AppContext);
    const {t} = useTranslation();

    const typeID = state.dashboard.selectedType || state.questionnaire!.panel.Types()[0].id;

    const type = state.questionnaire!.panel.Types().find((el: QuestionnaireType) => el.id === typeID)!
    if(state.questionnaire!.panel!.TypeWeight(type) === 0) {
        const newType = state.questionnaire!.panel.Types().find((el:QuestionnaireType) => state.questionnaire!.panel!.TypeWeight(el) > 0)!
        dispatch({ type: DashboardStateTypes.UPDATETYPE, id: newType.id })
    }
    const content = type!.initiatives!.filter(x=>!x.isPowerBI).map((el: QuestionnaireInitiative) => {
        return (
            <div className='rounded-xl border-1 text-black bg-white shadow-xl m-8 justify-items-center p-2' key={el.id}>
                <ResultColoredValue className="text-3xl font-bold" value = {state.questionnaire!.panel!.InitiativeResult(el)}/>
                <h2 className='text-2xl font-bold'> {el.name} </h2>
                <div className='text-grey-400 text-base mb-2'> {t('terms.weight')}: <PercentFormat value={state.questionnaire!.panel.InitiativeWeight(el)} /></div>
                <InitiativeTable initiative={el} /> 
            </div>
        )
    });

    return (
        <div className='w-full grid grid-cols-2 bg-white'>
            {content}
        </div>
    )
}

export default InitiativePanel;

