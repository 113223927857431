import { QuestionnaireIndicator, QuestionnaireInitiative } from '@icindex/v3-firebase';
import { PeriodDetail } from '../details/PeriodDetail';
import { IndicatorsTable } from '../details/tables/IndicatorsTable';
import { InitiativeValuesTable } from './InitiativeValuesTable';
import { Box, Button, MenuItem, Select, SelectChangeEvent, Tab, Tabs } from '@mui/material';
import { createRef, useContext, useRef, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '../components/TabPanel';
import { Bar, BarChart, CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { makePeriodLabels } from '../functions/TitleFunctions';
import { Title } from '@mui/icons-material';

export type InitiativeDetailProps = {
  initiative: QuestionnaireInitiative;
  baseYear: number;
}

export function InitiativeDetail(props: InitiativeDetailProps) {
  return (
    <div className="flex flex-col">
      {props.initiative.isPowerBI || <ResultTab initiative={props.initiative} /> }
      <PeriodDetail initiative={props.initiative} />
    </div>
  );
}


function ResultTab(props: { initiative: QuestionnaireInitiative }) {
  const [resultTab, setResultTab] = useState(0);
  const { state } = useContext(AppContext)
  const { t } = useTranslation();
  const [selectedGraph,setSelectedGraph] = useState("general")
  const graphRef = useRef(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setResultTab(newValue)
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectedGraph(event.target.value as string);
  };

  const graphTitle = selectedGraph === "general" ? props.initiative.name : props.initiative.indicators.find(x => x.id === selectedGraph)!.name;

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={resultTab} onChange={handleTabChange} id={props.initiative.id} key={props.initiative.id}
          sx={{
            "& button.Mui-selected": { color: state.theme.primary }
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: state.theme.primary

            }
          }}
        >
          <Tab label={t('terms.table')} id='0' key={0} />
          <Tab label={t('terms.graphics')} id='1' key={1} />
        </Tabs>
      </Box>
      <TabPanel value={resultTab} index={0} key={0}>
        <div className="flex">
          <div className="w-2/5 mr-8">
            <IndicatorsTable initiative={props.initiative} />
          </div>
          <div className="w-3/5 text-base">
            <InitiativeValuesTable initiative={props.initiative} />
          </div>
        </div>
      </TabPanel>
      <TabPanel value={resultTab} index={1} key={1}>
        <div className="flex justify-center text-sm w-full h-80">
            <div className="w-1/2 bg-white p-4 pb-8" ref={graphRef!}>
              <div className="mb-2 text-lg ">{graphTitle}</div>
              <div className="h-full w-full">
                {
                  selectedGraph === "general" ? (<IndicatorResultsChart initiative={props.initiative}/>) :
                  (<IndicatorValuesChart indicator={props.initiative.indicators.find(x => x.id === selectedGraph)!} initiative={props.initiative}/>)
                }
              </div>
          </div>
          <div className="w-1/12"/>
            <div className="flex flex-col w-1/4 gap-8 ">
              <Select className="w-full"  onChange={handleSelectChange} value={selectedGraph}>
                <MenuItem value={"general"}>{t('phrases.generalIndicatorResults')}</MenuItem>
                {
                  props.initiative.indicators.map(el => (<MenuItem value={el.id}>{el.name}</MenuItem>))
                }
              </Select>
              <Button onClick={() => {(window as any).saveDivAsImage(graphTitle,graphRef.current)}} variant="outlined">{t('phrases.saveGraph')}</Button>
            </div>
        </div>
      </TabPanel>
    </>
  )
}

const colors = [
  '#1f77b4', // Blue
  '#ff7f0e', // Orange
  '#2ca02c', // Green
  '#d62728', // Red
  '#9467bd', // Purple
  '#8c564b', // Brown
  '#e377c2', // Pink
  '#7f7f7f', // Gray
  '#bcbd22', // Yellow
  '#17becf', // Cyan
  '#ff9896', // Light Red
  '#aec7e8', // Light Blue
];

function IndicatorResultsChart(props:{
  initiative: QuestionnaireInitiative
}) {
  const { state } = useContext(AppContext)
  const { t } = useTranslation();

  const categories = makePeriodLabels(state.questionnaire?.panel!, props.initiative, t, state.questionnaire?.panel.BaseYear());

  const graphData = [];
  for (const indicator of props.initiative.indicators) {
    const data = state.questionnaire?.panel.IndicatorResults(indicator, state.questionnaire?.panel.InitiativePeriod(props.initiative),true)
    for (var catIdx = 0; catIdx < categories.length; catIdx++) {
      var aux: any = graphData.find(x => x.name === categories[catIdx])
      if (!aux) {
        aux = { "name": categories[catIdx] }
        graphData.push(aux)
      }
      if (data)
        aux[indicator.name] = data[catIdx] || 0
    }
  }

 
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={graphData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Legend />
          {props.initiative.indicators.map((el, idx) =>
            (<Bar dataKey={el.name} fill={colors[idx % colors.length]} />))
          }
      </BarChart>
    </ResponsiveContainer>
    )
}

function IndicatorValuesChart(props:{
  initiative:QuestionnaireInitiative,
  indicator: QuestionnaireIndicator
}) {
  const { state } = useContext(AppContext)
  const { t } = useTranslation();

  const categories = makePeriodLabels(state.questionnaire?.panel!, props.initiative, t, state.questionnaire?.panel.BaseYear());

  const graphData = [];
  const data = state.questionnaire?.panel.Values(props.indicator);
  for (var catIdx = 0; catIdx < categories.length; catIdx++) {
    var aux: any = graphData.find(x => x.name === categories[catIdx])
    if (!aux) {
      aux = { "name": categories[catIdx] }
      graphData.push(aux)
    }
    if (data){
      aux[props.indicator.name] = isNaN(data[catIdx]) ? 0 : data[catIdx]
    }
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={graphData}>
          <CartesianGrid strokeDasharray="3 3" >
          </CartesianGrid>
          <XAxis dataKey="name" >
          </XAxis>
          <YAxis >
          </YAxis>
          <Tooltip />
          <Line type="monotone" dataKey={props.indicator.name} stroke={colors[1]} />
      </LineChart>
    </ResponsiveContainer>
    )
}