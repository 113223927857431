import { useState, useEffect, useContext } from "react";
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Database from "@icindex/v3-firebase/dist/Database";
import { UserInfo } from "@icindex/v3-firebase";
import { AppContext } from "../context/AppContext";
import { QuestionnaireStateTypes } from "../context/QuestionnaireState";
import { useTranslation } from "react-i18next";
import ExportResultsToExcel from "./ExportResultsToExcel";
import ExportDataToExcel from "./ExportDataToExcel";

function stringToColor(string: string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name: string|undefined) {
    const theName = name || "Usuário IC-Index"
    const parts = theName.split(' ')
    return {
      sx: {
        bgcolor: stringToColor(theName),
      },
      children: `${parts[0][0]}${parts.length > 1 ? parts[1][0] : ''}`,
    };
  }

export default function AvatarMenu() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [name,setName] = useState<string|undefined>(undefined);

    const {dispatch} = useContext(AppContext)

    useEffect(()=>{
        Database.getUserInfo().then((info:UserInfo|null) => {
            if(info) {
                setName(info.displayName);
            }
        })
    },[]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout= () => {
        Database.logout();
        dispatch({type: QuestionnaireStateTypes.CLOSE})
    }

    const handleCloseQuestionnaire= () => {
        dispatch({type: QuestionnaireStateTypes.CLOSE})
    }

    const handleExportExcel= () => {
    }
    
    const {t} = useTranslation();

    return (
        <>
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar {...stringAvatar(name)} />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            left: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <MenuItem >
                    {name}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleCloseQuestionnaire}>{t('phrases.selectQuestionnaire')}</MenuItem>
                <ExportResultsToExcel/>
                <ExportDataToExcel/>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </>
    );
}

