import React, { useState } from 'react';
import ICIndexBoard from './ICIndexBoard';
import TypePanel from './TypePanel';
import InitiativePanel from './InitiativePanel';


function Dashboard() {
    return (
        <div className='flex-col w-full px-3 bg-transparent mb-3'>
            <div className= 'mb-2'>
                <ICIndexBoard/>
            </div>

            <div className='mb-2'>
                <TypePanel/>
            </div>

            <div className=' mb-2'>
                <InitiativePanel/>
            </div>
        </div>  
    )
}

export default Dashboard;
