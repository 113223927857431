import { useContext } from 'react';
import GaugeChart from 'react-gauge-chart'
import { AppContext,AppType } from '../context/AppContext';
import { DashboardStateTypes } from '../context/DashboardState';
import { PercentFormat } from './PercentFormat';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GaugeDisplay } from './GaugeDisplay';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';


export function getColor(state:AppType,value:number) {
    const thresholds = state.questionnaire?.panel.Thresholds();
    let color = '#000';
    if(value > thresholds!.med)
        color = state.theme.grade.hi;
    else if(value > thresholds!.low)
        color = state.theme.grade.med;
    else
        color = state.theme.grade.low;

    return color
}

export type TypeResultProps = {
    label: string;
    value: number;
    weight: number;
    id: string;
}

export function TypeResult(props: TypeResultProps) {
    let displayDiv = 'flex flex-col rounded-xl border-1 border-black shadow-xl mx-4 py-4 bg-zinc-300 text-black w-full '
    const { state, dispatch } = useContext(AppContext)
    const typeID = state.dashboard.selectedType || state.questionnaire!.panel.Types()[0].id

    const handleClick = () => {
        dispatch({ type: DashboardStateTypes.UPDATETYPE, id: props.id })
    }

    if (typeID != props.id){
        displayDiv = displayDiv + ' opacity-40 ';
    }

    const thresholds = state.questionnaire?.panel.Thresholds()!;
    const colors = [
        ...Array(Math.ceil(thresholds.low/0.05)).fill(state.theme.grade.low),
        ...Array(Math.ceil((thresholds.med-thresholds.low)/0.05)).fill(state.theme.grade.med),
        ...Array((Math.ceil((1-thresholds.med)/0.05))==0?1:(Math.ceil((1-thresholds.med)/0.05))).fill(state.theme.grade.hi),

    ]

    const { t} = useTranslation() 

    return (   
        <div className={displayDiv}>
            <div className='flex-none h-3/5 flex justify-center'>
                <div className='flex-none'>
                    <GaugeDisplay value={props.value} />
                </div>
            </div>
            <div className='h-2/5 pt-4'>
                <h1 className='text-xl font-bold'> {props.label} </h1>
                <h2 className='text-base'> {t('terms.weight')}: <PercentFormat value={props.weight} /> </h2>
                <div className='flex justify-center cursor-pointer'><VisibilityIcon onClick={handleClick} /> </div>
            </div>
        </div>
    )
}

export type ResultColoredValueProps = 
{
    className: string,
    value:number
}

export function ResultColoredValue(props:ResultColoredValueProps) {

    const {state} = useContext(AppContext)

    return (
        <h1 className={props.className} style={{color:getColor(state,props.value)}}> 
            <PercentFormat value={props.value} />
        </h1>
    )
}


