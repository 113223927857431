import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import './SignIn.css';
import { useContext, useEffect, useState } from 'react';
import Database from '@icindex/v3-firebase/dist/Database';
import BackgroundVideo from './BackgroundVideo';
import { Button, CircularProgress, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import ForwardOutlined from '@mui/icons-material/ForwardOutlined';
import { QuestionnaireInfo, QuestionnaireType } from '@icindex/v3-firebase';
import { AppContext } from '../context/AppContext';
import { QuestionnaireStateTypes } from '../context/QuestionnaireState';
import { useTranslation } from 'react-i18next';
import { Delete, Handyman } from '@mui/icons-material';
import ImportJsonWizard from './ImportJsonWizard';
import LogoutIcon from '@mui/icons-material/Logout';
export default function SelectQuestionnaire() {

  const [infos, setInfos] = useState<QuestionnaireInfo[] | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const [userName, setUserName] = useState<string | null>(null)
  const [startImport, setStartImport] = useState(false);
  const [tenant,setTenant] = useState('')
  useEffect(() => {
    Database.getQuestionnaires().then((items: QuestionnaireInfo[]) => {
      setInfos(items);
    })
    Database.getUserInfo().then(user => {
      if (user !== null) {
        setUserName(user!.displayName)
        setTenant(user!.tenant)
      }
    })
  }, [])

  const importDone = () => {
    Database.getQuestionnaires()
      .then((items: QuestionnaireInfo[]) => { setInfos(items); setStartImport(false) })
  }

  const loadQuestionnaire = (info: QuestionnaireInfo) => {
    setLoading(true);
    Database.loadQuestionnaire(info)
      .then((types: QuestionnaireType[]) => {
        dispatch({ type: QuestionnaireStateTypes.LOAD, data: { info, types } })
      })
      .catch((reason: any) => {
        console.log(reason)
        setLoading(false);
      });
  }

  const deleteQuestionnaire = (info: QuestionnaireInfo) => {
    setLoading(true);
    Database.deleteQuestionnaire(info)
      .then(() => {
        Database.getQuestionnaires().then((items: QuestionnaireInfo[]) => {
          setLoading(false)
          setInfos(items);
        })
      })
      .catch((reason: any) => {
        console.log(reason)
        setLoading(false);
      });
  }

  var content = null;

  if (loading) {
    content = (<CircularProgress />)
  }
  else {
    if (infos !== undefined) {
      console.log('infos',infos)
      content = (
        <>
          <List dense={false}>
            {
              infos.map((el: QuestionnaireInfo) => (
                <ListItem key={el.docId}
                  secondaryAction={(
                    <div className="flex gap-2">
                      {/* {el.draft ? (
                        <IconButton edge="end" aria-label="delete" onClick={() => deleteQuestionnaire(el)}>
                          <Delete />
                        </IconButton>) : null} */}
                      <IconButton edge="end" aria-label="open" onClick={() => loadQuestionnaire(el)}>
                        <ForwardOutlined />
                      </IconButton>
                    </div>
                  )}
                >
                  <ListItemAvatar className={`${el.readonly || el.draft ? "opacity-100" : "opacity-0"}`}>
                    <div className="flex gap-2">
                      {el.readonly ? (<Avatar><LockOutlinedIcon /></Avatar>) : null}
                      {el.draft ? (<Avatar><Handyman /></Avatar>) : null}
                    </div>
                  </ListItemAvatar>
                  <ListItemText className="px-8"
                    primary={el.title ? el.title : el.docId}
                  />
                </ListItem>
              ))
            }
          </List>
          {
            tenant === 'acao' ? (
              <Button variant="contained" onClick={() => setStartImport(true)}>Importar Questionário do Plugin do Excel</Button>
            ):null
          }
        </>
      )
    }
  }

  let welcomeDiv = null;
  const { t } = useTranslation()


  const handleLogout = () => {
    Database.logout();
    dispatch({type: QuestionnaireStateTypes.CLOSE})    
  }

  if (userName !== null) {
    welcomeDiv = (
      <div className="flex w-1/3 mb-6 justify-center items-center">
        <p className='text-2xl font-bold text-grey-100 mr-5'>{t('terms.hello')} {userName}</p>
        <IconButton component="label" onClick={handleLogout} >
          <LogoutIcon sx={{ color: '#F5F5F5' }}/>
        </IconButton>
      </div>
    )
  }

  return (
    <>
      <BackgroundVideo />
      <div className="flex flex-col justify-center items-center h-screen">
        {welcomeDiv}
        <div className="flex w-1/3 mb-3">
          <img className='w-1/3' src={`/images/${state.theme.logo}`} ></img>
          <div className='grow'></div>
          <img className='w-1/3' src='/images/logoICIndex.png' ></img>
        </div>
        <div className="flex flex-col items-center w-1/3 bg-white h-fit p-8 opacity-80 gap-6">
          {content}
        </div>
      </div>
      <ImportJsonWizard done={importDone} cancel={() => setStartImport(false)} visible={startImport} />
    </>
  )
}