import { QuestionnaireIndicator } from '@icindex/v3-firebase';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React from 'react';

export type IndicatorDescriptionProps = {
    indicator: QuestionnaireIndicator;
}
export function IndicatorDescription(props: IndicatorDescriptionProps) {
    const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (props.indicator.description) {
        return (<>
            <InfoOutlinedIcon 
                sx={{ fontSize: 12 }} 
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            />
            <Popover
            id="mouse-over-popover"
            sx={{
            pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <Typography sx={{ p: 1 }}>{props.indicator.description}</Typography>
        </Popover>
        </>)
    }
    else {
        return null;
    }
}

