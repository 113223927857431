import { Button, Menu, MenuItem } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../context/AppContext';
import { QuestionnaireStateTypes } from '../context/QuestionnaireState';
import { DashboardStateTypes } from '../context/DashboardState';

function TopBar() {
    const { state,dispatch } = useContext(AppContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const {t} = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const setUnit = (unit?:string) =>{
        state.questionnaire!.panel.SetUnit(unit);
        handleClose();
        dispatch({type:QuestionnaireStateTypes.REFRESH});
    }

    if(!state.questionnaire!.panel.Units()) return null;

    return (
        <>
            <Button variant='contained' onClick={handleClick}>{state.questionnaire!.panel.CurrentUnit() || t('phrases.allUnits')}</Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                ><MenuItem key={-1} onClick={()=>setUnit()}>{t('phrases.allUnits')}</MenuItem>
                {
                    state.questionnaire!.panel.Units().map((unit,idx) => (<MenuItem key={idx} onClick={()=>setUnit(unit)}>{unit}</MenuItem>))
                }
            </Menu>
        </>
    );
}

export default TopBar;
