import { CollectorsTable } from './collectors/CollectorsTable';
import { QuestionnaireInitiative } from '@icindex/v3-firebase';

export type PeriodDetailProps = {
  initiative: QuestionnaireInitiative;
}

export function PeriodDetail(props: PeriodDetailProps) {
  return (
      <div className="mt-6"> <CollectorsTable initiative={props.initiative}/> </div>    
  )
}
