import { MenuItem } from "@mui/material";
import { TFunction } from "i18next";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext, AppType } from "../context/AppContext";
import { makeHeaderTitle } from "../functions/TitleFunctions";

export default function ExportResultsToExcel() {
    const {t} = useTranslation();
    const { state } = useContext(AppContext);


    return (
        <MenuItem onClick={()=>Export(t,state)}>{t('phrases.exportExcel')}</MenuItem>
    );
}

function Export(t:TFunction,state:AppType) {
    const ExcelJS = require("exceljs");
    var workbook = new ExcelJS.Workbook();
    const oldUnit = state.questionnaire?.panel.CurrentUnit();
    const units = ['ALL',...(state.questionnaire?.panel.Units() || [])];
    units.forEach(unit => {
      if(units.length > 1) state.questionnaire?.panel.SetUnit(unit);
      makeDashboard(workbook, t, state,units.length > 1 ? unit : undefined);
      makeType(state, workbook, t,units.length > 1 ? unit : undefined);
    })
    if(units.length > 1) {
      state.questionnaire?.panel.SetUnit(oldUnit);
    }

    // var that = this;
    workbook.xlsx.writeBuffer().then(function (buffer:any) {
      var dataURI =
        "data:application/xlsx;base64," + buffer.toString("base64");

    var fileName = 'ICIndex_Resultados.xlsx';

      var a = document.createElement("a");
      a.href = dataURI;
      a.download = fileName;
      a.click();
    });    
}

function makeType(state: AppType, workbook: any, t: TFunction<"translation", undefined, "translation">,unit?:string) {
  state.questionnaire?.panel.Types()!.forEach((type) => {
    var sheet = workbook.addWorksheet(
      `${(!unit? '' : `${unit}-`)}${type.title}`  
    );

    sheet.getColumn(1).width = 33;
    sheet.getColumn(2).width = 8;
    sheet.getColumn(3).width = 8;
    sheet.getColumn(4).width = 1.71;
    for (var x = 5; x <= 16; x++) {
      sheet.getColumn(x).width = 6;
    }

    sheet.getCell("A1").value = state.questionnaire?.panel.Title();
    sheet.getCell("A1").font = {
      name: "Roboto",
      size: 14,
      bold: true,
      color: { argb: "FFED7D31" },
    };

    sheet.getRow(1).height = 30;
    sheet.getRow(1).alignment = { vertical: "middle" };

    sheet.getRow(2).height = 7.5;

    sheet.getCell("A3").value = type.title!.toUpperCase();
    sheet.getCell("A3").font = {
      name: "Calibri",
      size: 11,
      bold: true,
      color: { argb: "FF000000" },
    };

    var currentLine = 5;
    state.questionnaire!.panel.Initiatives(type)!.forEach((initiative) => {
      for (var idx = 10; idx <= 25; idx++) {
        sheet.getCell(
          `${idx.toString(36).toUpperCase()}${currentLine}`
        ).fill = {
          pattern: "solid",
          type: "pattern",
          fgColor: { argb: "FF000000" },
        };
        sheet.getCell(
          `${idx.toString(36).toUpperCase()}${currentLine}`
        ).font = {
          name: "Calibri",
          size: 10,
          bold: true,
          color: { argb: "FFFFFFFF" },
        };
      }

      sheet.getCell(`A${currentLine}`).value = initiative.name;

      currentLine += 2;

      sheet.getRow(currentLine).height = 24;
      sheet.getRow(currentLine).alignment = { vertical: "middle" };

      var totalCols = 12;
      var period = initiative.basePeriod!;
      if (period === "quarter") {
        totalCols = 4;
      } else if (period === "semester") {
        totalCols = 2;
      } else if (period === "year") {
        totalCols = 1;
      }

      for (var idx = 10; idx <= 13 + totalCols; idx++) {
        if (idx != 13) {
          sheet.getCell(
            `${idx.toString(36).toUpperCase()}${currentLine}`
          ).fill = {
            pattern: "solid",
            type: "pattern",
            fgColor: { argb: "FF808080" },
          };
          sheet.getCell(
            `${idx.toString(36).toUpperCase()}${currentLine}`
          ).font = {
            name: "Calibri",
            size: 9,
            bold: true,
            color: { argb: "FFFFFFFF" },
          };
          sheet.getCell(
            `${idx.toString(36).toUpperCase()}${currentLine}`
          ).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          sheet.getCell(
            `${idx.toString(36).toUpperCase()}${currentLine}`
          ).alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true,
          };
        }
      }

      sheet.getCell(`A${currentLine}`).value = t('terms.goals');
      sheet.getCell(`B${currentLine}`).value = `${t('terms.goal')}/${t("terms.period")} `;
      sheet.getCell(`C${currentLine}`).value = t('terms.weight');

      if (initiative.basePeriod === "year") {
        sheet.getCell(`E${currentLine}`).value =
          state.questionnaire?.panel.BaseYear();
      } else if (initiative.basePeriod === "semester") {
        sheet.getCell(
          `E${currentLine}`
        ).value = `Sem1 / ${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(
          `F${currentLine}`
        ).value = `Sem2 / ${state.questionnaire?.panel.BaseYear()}`;
      } else if (initiative.basePeriod === "quarter") {
        sheet.getCell(`E${currentLine}`).value = `${t('initials.quarter')}1/${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(`F${currentLine}`).value = `${t('initials.quarter')}2/${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(`G${currentLine}`).value = `${t('initials.quarter')}3/${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(`H${currentLine}`).value = `${t('initials.quarter')}4/${state.questionnaire?.panel.BaseYear()}`;
      } else {
        const months = t('initials.months', { returnObjects: true }) as string[];
        sheet.getCell(`E${currentLine}`).value = `${months[0]}/${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(`F${currentLine}`).value = `${months[1]}/${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(`G${currentLine}`).value = `${months[2]}/${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(`H${currentLine}`).value = `${months[3]}/${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(`I${currentLine}`).value = `${months[4]}/${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(`J${currentLine}`).value = `${months[5]}/${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(`K${currentLine}`).value = `${months[6]}/${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(`L${currentLine}`).value = `${months[7]}/${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(`M${currentLine}`).value = `${months[8]}/${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(`N${currentLine}`).value = `${months[9]}/${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(`O${currentLine}`).value = `${months[10]}/${state.questionnaire?.panel.BaseYear()}`;
        sheet.getCell(`P${currentLine}`).value = `${months[11]}/${state.questionnaire?.panel.BaseYear()}`;
      }

      initiative.indicators.forEach((indicator) => {
        currentLine++;
        sheet.getCell(`A${currentLine}`).value = indicator.name;
        sheet.getCell(`B${currentLine}`).value = indicator.target;
        sheet.getCell(`C${currentLine}`).value = state.questionnaire?.panel.IndicatorWeight(indicator);
        sheet.getCell(`C${currentLine}`).numFmt = '0.00%';

        for (var idx = 10; idx <= 13 + totalCols; idx++) {
          if (idx != 13) {
            sheet.getCell(
              `${idx.toString(36).toUpperCase()}${currentLine}`
            ).font = {
              name: "Calibri",
              size: 9,
              bold: false,
              color: { argb: "FF000000" },
            };
            sheet.getCell(
              `${idx.toString(36).toUpperCase()}${currentLine}`
            ).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            sheet.getCell(
              `${idx.toString(36).toUpperCase()}${currentLine}`
            ).alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
          }
        }

        const indicatorResults = state.questionnaire?.panel.Values(indicator)!;
        for (var idx = 0; idx < totalCols; idx++) {
          sheet.getCell(
            `${(idx + 14).toString(36).toUpperCase()}${currentLine}`
          ).value = isNaN(indicatorResults[idx]) ? 'N/A' : indicatorResults[idx];
        }
      });
      currentLine++;
      const totals = state.questionnaire?.panel.Totals(initiative)!;
      for (var idx = 0; idx < totalCols; idx++) {
        var cell = sheet.getCell(
          `${(idx + 14).toString(36).toUpperCase()}${currentLine}`
        );
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.font = {
          name: "Calibri",
          size: 8,
          bold: true,
          color: { argb: "FF000000" },
        };
        cell.alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        cell.fill = {
          pattern: "solid",
          type: "pattern",
          fgColor: { argb: "FFD9D9D9" },
        };
        cell.value = totals[idx];
        cell.numFmt = '0.00%';
      }

      currentLine += 2;
    });
  });
}

function makeDashboard(workbook: any, t: TFunction<"translation", undefined, "translation">, state: AppType,unit?:string) {
  var sheet = workbook.addWorksheet(
    `${(!unit? '' : `${unit}-`)}${t('terms.dashboard')}`
  );

  sheet.getColumn(1).width = 33;
  sheet.getColumn(2).width = 5.86;
  sheet.getColumn(3).width = 13.29;
  sheet.getColumn(4).width = 13.29;
  sheet.getColumn(5).width = 13.29;
  sheet.getColumn(6).width = 13.29;
  sheet.getColumn(7).width = 13.29;

  sheet.getCell("A1").value = state.questionnaire!.panel.Title();
  sheet.getCell("A1").font = {
    name: "Roboto",
    size: 14,
    bold: true,
    color: { argb: "FFED7D31" },
  };

  sheet.getRow(1).height = 30;
  sheet.getRow(1).alignment = { vertical: "middle" };

  sheet.getRow(2).height = 7.5;

  sheet.getCell("A3").value = t('terms.dashboard').toUpperCase();
  sheet.getCell("A3").font = {
    name: "Calibri",
    size: 11,
    bold: true,
    color: { argb: "FF000000" },
  };

  for (var idx = 10; idx < 20; idx++) {
    sheet.getCell(`${idx.toString(36).toUpperCase()}5`).fill = {
      pattern: "solid",
      type: "pattern",
      fgColor: { argb: "FFD9D9D9" },
    };
  }
  sheet.getRow(5).height = 18.75;
  sheet.getRow(5).alignment = { vertical: "middle" };

  sheet.getCell("C5").value = "INTERNAL COMMUNICATION INDEX";
  sheet.getCell("C5").font = {
    name: "Calibri",
    size: 12,
    bold: false,
    color: { argb: "FF000000" },
  };

  sheet.getCell("F5").value = state.questionnaire?.panel.Result();
  sheet.getCell("F5").numFmt = '0.00%';
  sheet.getCell("F5").font = {
    name: "Calibri",
    size: 14,
    bold: true,
    color: { argb: "FF000000" },
  };
  sheet.getCell("F5").alignment = { horizontal: "center" };

  for (idx = 10; idx < 13; idx++) {
    sheet.getCell(`${idx.toString(36).toUpperCase()}7`).fill = {
      pattern: "solid",
      type: "pattern",
      fgColor: { argb: "FF000000" },
    };
  }

  sheet.getCell("A7").font = {
    name: "Calibri",
    size: 10,
    bold: true,
    color: { argb: "FFFFFFFF" },
  };
  sheet.getCell("A7").value = t('terms.strategicdimension');

  sheet.getCell("B7").font = {
    name: "Calibri",
    size: 8,
    bold: true,
    color: { argb: "FFFFFFFF" },
  };
  sheet.getCell("B7").value = t('terms.weight');

  sheet.getCell("C7").font = {
    name: "Calibri",
    size: 10,
    bold: true,
    color: { argb: "FFFFFFFF" },
  };
  sheet.getCell("C7").value = t('terms.performance');

  var currentLine = 8;
  state.questionnaire?.panel.Types().forEach((type) => {
    sheet.getCell(`A${currentLine}`).value = type.title;
    sheet.getCell(`A${currentLine}`).font = {
      name: "Calibri",
      size: 10,
      bold: false,
    };
    sheet.getCell(`A${currentLine}`).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    sheet.getCell(`B${currentLine}`).font = {
      name: "Calibri",
      size: 8,
      bold: false,
    };
    sheet.getCell(`B${currentLine}`).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    sheet.getCell(`B${currentLine}`).value = type.weight;
    sheet.getCell(`B${currentLine}`).numFmt = '0.00%';
    sheet.getCell(`B${currentLine}`).alignment = { horizontal: "center" };

    var result = state.questionnaire?.panel.TypeResult(type)!;
    sheet.getCell(`C${currentLine}`).value = result;
    sheet.getCell(`C${currentLine}`).numFmt = '0.00%';

    sheet.getCell(`C${currentLine}`).font = {
      name: "Calibri",
      size: 10,
      bold: true,
    };
    sheet.getCell(`C${currentLine}`).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell(`C${currentLine}`).alignment = { horizontal: "center" };

    currentLine++;
  });

  currentLine += 2;

  state.questionnaire?.panel.Types().forEach((type) => {
    for (var idx = 10; idx < 16; idx++) {
      sheet.getCell(
        `${idx.toString(36).toUpperCase()}${currentLine}`
      ).fill = {
        pattern: "solid",
        type: "pattern",
        fgColor: { argb: "FF000000" },
      };
      sheet.getCell(
        `${idx.toString(36).toUpperCase()}${currentLine}`
      ).font = {
        name: "Calibri",
        size: idx == 11 ? 8 : 10,
        bold: true,
        color: { argb: "FFFFFFFF" },
      };
    }

    sheet.mergeCells(`C${currentLine}:F${currentLine}`);
    sheet.getCell(`C${currentLine}`).alignment = { horizontal: "center" };

    sheet.getCell(`A${currentLine}`).value = type.title;
    sheet.getCell(`B${currentLine}`).value = t("terms.weight");
    sheet.getCell(`C${currentLine}`).value = t("terms.performanceinperiod"
    );
    currentLine += 2;

    type.initiatives!.forEach((initiative) => {
      var initiativeResult = state.questionnaire?.panel.InitiativeResults(initiative)!;

      for (var idx = 10; idx < 12 + initiativeResult.length; idx++) {
        sheet.getCell(
          `${idx.toString(36).toUpperCase()}${currentLine}`
        ).fill = {
          pattern: "solid",
          type: "pattern",
          fgColor: { argb: "FF808080" },
        };
        sheet.getCell(
          `${idx.toString(36).toUpperCase()}${currentLine}`
        ).font = {
          name: "Calibri",
          size: idx == 11 ? 8 : 10,
          bold: true,
          color: { argb: "FFFFFFFF" },
        };
        if (idx > 10) {
          sheet.getCell(
            `${idx.toString(36).toUpperCase()}${currentLine}`
          ).alignment = { horizontal: "center" };
        }
        sheet.getCell(
          `${idx.toString(36).toUpperCase()}${currentLine}`
        ).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }

      sheet.getCell(`A${currentLine}`).value = initiative.name;
      sheet.getCell(`B${currentLine}`).value = state.questionnaire?.panel.TypeWeight(type);
      sheet.getCell(`B${currentLine}`).numFmt = '0.00%';

      initiativeResult.forEach((result, idx) => {
        const col = makeHeaderTitle(state.questionnaire!.panel, initiative, idx, state.questionnaire!.panel.BaseYear(), t);
        sheet.getCell(
          `${(12 + idx).toString(34).toUpperCase()}${currentLine}`
        ).value = col;
      });

      currentLine++;

      initiative.indicators.forEach((indicator) => {
        for (idx = 10; idx <
          12 + initiativeResult.length; idx++) {
          sheet.getCell(
            `${idx.toString(36).toUpperCase()}${currentLine}`
          ).font = {
            name: "Calibri",
            size: idx == 11 ? 8 : 10,
            bold: idx > 11,
          };
          if (idx > 10) {
            sheet.getCell(
              `${idx.toString(36).toUpperCase()}${currentLine}`
            ).alignment = { horizontal: "center" };
          }
          sheet.getCell(
            `${idx.toString(36).toUpperCase()}${currentLine}`
          ).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }

        sheet.getCell(`A${currentLine}`).value = indicator.name;
        sheet.getCell(`B${currentLine}`).value = state.questionnaire?.panel.IndicatorWeight(indicator);
        sheet.getCell(`B${currentLine}`).numFmt = ' 0.00%';

        for (idx = 12; idx <
          12 + initiativeResult.length; idx++) {
          var theValue = initiativeResult[idx - 12];
          if (isNaN(theValue))
            theValue = state.questionnaire?.panel.Goal()!;
          sheet.getCell(
            `${idx.toString(36).toUpperCase()}${currentLine}`
          ).value = theValue;
          sheet.getCell(
            `${idx.toString(36).toUpperCase()}${currentLine}`
          ).numFmt = '0.00%';
        }

        currentLine++;
      });

      for (var idx = 10; idx < 12 + initiativeResult.length; idx++) {
        sheet.getCell(
          `${idx.toString(36).toUpperCase()}${currentLine}`
        ).fill = {
          pattern: "solid",
          type: "pattern",
          fgColor: { argb: "FFD9D9D9" },
        };
        sheet.getCell(
          `${idx.toString(36).toUpperCase()}${currentLine}`
        ).font = {
          name: "Calibri",
          size: idx == 11 ? 8 : 10,
          bold: true,
        };
        if (idx > 10) {
          sheet.getCell(
            `${idx.toString(36).toUpperCase()}${currentLine}`
          ).alignment = { horizontal: "center" };
        }
        sheet.getCell(
          `${idx.toString(36).toUpperCase()}${currentLine}`
        ).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }

      sheet.getCell(`A${currentLine}`).value = "Total";
      for (idx = 12; idx < 12 + initiativeResult.length; idx++) {
        var theValue = initiativeResult[idx - 12];
        if (isNaN(theValue))
          theValue = state.questionnaire!.panel.Goal();
        sheet.getCell(
          `${idx.toString(36).toUpperCase()}${currentLine}`
        ).value = theValue;
        sheet.getCell(
          `${idx.toString(36).toUpperCase()}${currentLine}`
        ).numFmt = '0.00%';
      }
      currentLine += 2;
    });
    currentLine += 2;
  });
}

