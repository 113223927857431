import { QuestionnaireInfo, QuestionnaireInitiative, QuestionnairePanel, QuestionnaireType } from "@icindex/v3-firebase";
import Database from "@icindex/v3-firebase/dist/Database";
import { MemoryTable } from "@icindex/v3-firebase/dist/memory/MemoryTable";

export type QuestionnaireState = {
    panel: QuestionnairePanel,
    memory: MemoryTable,
    powerBiInitiatives: (QuestionnaireInitiative & {typeName:string})[]
} ;

export enum QuestionnaireStateTypes {
    LOAD = "LOAD",
    CLOSE = "CLOSE",
    REFRESH = "REFRESH"
}

export type QuestionnaireActions = 
  { type: QuestionnaireStateTypes.LOAD, data: {info:QuestionnaireInfo, types:QuestionnaireType[]}}
| { type: QuestionnaireStateTypes.CLOSE}
| { type: QuestionnaireStateTypes.REFRESH}

export const questionnaireReducer = (state:QuestionnaireState|null,action:QuestionnaireActions): QuestionnaireState|null => {
    switch(action.type) {
        case QuestionnaireStateTypes.LOAD:
            const memory = new MemoryTable();
            const panel = new QuestionnairePanel(action.data.info,action.data.types,memory); 

            const powerBi:(QuestionnaireInitiative & {typeName:string})[] = [];
            for(const t of panel.Types()) {
                for(const i of t.initiatives!) {
                    if(i.isPowerBI) {
                        powerBi.push({...i,typeName:t.title!})
                    }
                }
            }
            return {...action.data,panel,memory,powerBiInitiatives:powerBi};
        case QuestionnaireStateTypes.CLOSE:
            return null;
        case QuestionnaireStateTypes.REFRESH:
            Database.getLogger().debug("Questionnaire state REFRESH")
            return {...state!};
        default:
            return state;
    }
};
