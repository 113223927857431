import { QuestionnaireIndicator, QuestionnaireInitiative } from '@icindex/v3-firebase';
import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PercentFormat } from '../../components/PercentFormat';
import { AppContext } from '../../context/AppContext';
import { IndicatorDescription } from '../../components/InitiativeDescription';

export type IndicatorsTableProps = {
  initiative: QuestionnaireInitiative;
}


export function IndicatorsTable(props: IndicatorsTableProps) {
  const {state} = useContext(AppContext);
  const headerClass = `text-white ${state.theme.background}-600 p-2 font-bold text-base`
  const bodyClass = 'border-grey-400 p-2 border-b border-r text-black text-base'
  const lastBodyClass = 'border-grey-400 p-2 border-r text-black'

  const content = props.initiative.indicators.map((indicator: QuestionnaireIndicator) => (
    <Fragment key={indicator.id}>
        <div className={`${bodyClass} col-span-4`}> {indicator.name} <IndicatorDescription indicator={indicator}/> </div>
       { 
        indicator.display === 'percentage'?
          (<div className={bodyClass}> <PercentFormat value={state.questionnaire!.panel.IndicatorTarget(indicator)} /> </div> ):
          (<div className={bodyClass}> {state.questionnaire!.panel.IndicatorTarget(indicator)} </div>)
        }

        <div className={bodyClass.replace('border-r','')}> <PercentFormat value={state.questionnaire!.panel.IndicatorWeight(indicator)} /> </div>
    </Fragment>
))

const {t} = useTranslation();

return (
    <div className='grid grid-cols-6 border-grey-400 rounded-md border'>
        <div className={`${headerClass} col-span-4`}> {t('terms.indicators')} </div>
        <div className={headerClass}> {t('terms.goal')} </div>
        <div className={headerClass.replace('border-r','')}> {t('terms.weight')} </div>
        {content}    
    </div>
)
}
