import { useContext, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useConfirm } from "material-ui-confirm";
import { AppContext } from '../../context/AppContext';
import { DashboardStateTypes } from '../../context/DashboardState';

import React from 'react';
import { QuestionnaireData, QuestionnaireInitiative } from '@icindex/v3-firebase';
import DataTable from './DataTable';
import { DataDialog } from './DataDialog';
import { QuestionnaireStateTypes } from '../../context/QuestionnaireState';
import Database from '@icindex/v3-firebase/dist/Database';
import AnalysisEditor from './AnalysisEditor';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '../../components/TabPanel';


export type CollectorsMonthProps = {
  initiative: QuestionnaireInitiative
}

export function CollectorsTable(props: CollectorsMonthProps) {
  const {t} = useTranslation()
  const month:string[] = t('initials.months',{returnObjects:true})
  const { state, dispatch } = useContext(AppContext)
  const [data,setData] = useState<QuestionnaireData|undefined|null>();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      dispatch({ type: DashboardStateTypes.UPDATEMONTH, idMonth: newValue  })
  };

  const handleDataChanged = (data?:QuestionnaireData) => {
    if(data) {
      const theThen = (initiative:QuestionnaireInitiative) => {
        dispatch({type:QuestionnaireStateTypes.REFRESH})
      };

      const theCatch = (err:any) => {
        console.log(err);
      }
      if(data.docId) {
        Database.updateData(props.initiative,data).then(theThen).catch(theCatch);
      }
      else {
        Database.addData(props.initiative,data,state.questionnaire?.panel.CurrentUnit()).then(theThen).catch(theCatch);
      }
    }
    setData(undefined);
  }

  const handleAnalysisChanged = (value?:string) => {
    if(!value) value = '';
    Database.updateQuantitativeAnalysis(props.initiative,value).then((initiative:QuestionnaireInitiative) => {
      dispatch({type:QuestionnaireStateTypes.REFRESH})
    })
  }

  const confirm = useConfirm();
  const handleDelete = (data:QuestionnaireData) => {
    confirm({
      description: 'Confirma exclusão?',
      title: 'Excluir Anotação'
    }).then(()=>{
      Database.deleteData(props.initiative,data).then(()=>{
        state.questionnaire?.panel.Refresh(props.initiative);
        dispatch({type:QuestionnaireStateTypes.REFRESH})
      }).catch((reason:any) => {
        console.log(reason)
      })
    })
  }

  const period = state.questionnaire!.panel.InitiativePeriod(props.initiative)
  if(period === "month")
  {
    var color = state.theme.detail?.type?.SelectedTabCaption || state.theme.primary

    var content = (
      <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={state.dashboard.selectedMonth} onChange={handleTabChange} id={props.initiative.id} key={props.initiative.id}
                    sx={{
                      "& button.Mui-selected":{color:`${color} !important`}
                  }}
                  TabIndicatorProps={{
                      sx:{
                          backgroundColor: color
                          
                      }
                  }}
            >
              {month.map((val: string, idx: number) => (
                <Tab label={val} id={`${idx}`} key={idx} />
              ))}
            </Tabs>
          </Box>
          {month.map((val: any, idx: number) => (
            <TabPanel value={state.dashboard.selectedMonth + 1} index={idx + 1} key={idx+1}>
              <div className="overflow-x-auto">
                <DataTable initiative={props.initiative} month={idx} onDelete={handleDelete} onEdit={(data:QuestionnaireData) => {setData(data)}} onAdd={()=>setData(null)}/>
              </div>
            </TabPanel>
          ))}
      </>
    );
  }
  else {
    var content = (
        <DataTable initiative={props.initiative} onDelete={handleDelete} onEdit={(data:QuestionnaireData) => {setData(data)}} onAdd={()=>setData(null)}/>
    )
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
      <div className="overflow-x-auto">
        {content}
      </div>
      </Box>
      <DataDialog initiative={props.initiative} data={data!} open={data !== undefined} callback={handleDataChanged} /> 
      <AnalysisEditor initiative={props.initiative} callback={handleAnalysisChanged}/>
    </>
  )
}


