import { MenuItem } from "@mui/material";
import { TFunction } from "i18next";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext, AppType } from "../context/AppContext";
import NumberFormat from "react-number-format";
import { makeHeaderTitle } from "../functions/TitleFunctions";

type SheetItem = {
  sheet:any,
  title: string,
  lastRow: number;
}

export default function ExportDataToExcel() {
    const {t} = useTranslation();
    const { state } = useContext(AppContext);


    return (
        <MenuItem onClick={()=>Export(t,state)}>{t('phrases.exportDataExcel')}</MenuItem>
    );
}

function Export(t:TFunction,state:AppType) {
    const ExcelJS = require("exceljs");
    var workbook = new ExcelJS.Workbook();
    const oldUnit = state.questionnaire?.panel.CurrentUnit();
    const units = ['ALL',...(state.questionnaire?.panel.Units() || [])];
    const sheets:SheetItem[] = [];
    units.forEach(unit => {
      if(units.length > 1) state.questionnaire?.panel.SetUnit(unit);
      makeType(state, workbook, t,sheets,units.length > 1 ? unit : undefined);
    })
    if(units.length > 1) {
      state.questionnaire?.panel.SetUnit(oldUnit);
    }

    // var that = this;
    workbook.xlsx.writeBuffer().then(function (buffer:any) {
      var dataURI =
        "data:application/xlsx;base64," + buffer.toString("base64");

    var fileName = 'ICIndex_Dados.xlsx';

      var a = document.createElement("a");
      a.href = dataURI;
      a.download = fileName;
      a.click();
    });    
}

function getCell(row:number,col:number): string {
  return `${(col+10).toString(36).toUpperCase()}${row}`
}

function makeType(state: AppType, workbook: any, t: TFunction<"translation", undefined, "translation">
  ,sheets:SheetItem[],unit?:string) {
  state.questionnaire?.panel.Types()!.forEach((type,idxType) => {
    type.initiatives!.forEach((initiative,idxInitiative) => {
      var title = `${idxType+1}.${idxInitiative+1}`
      var sheetItem = sheets.find(x => x.title === title)
      if(!sheetItem) {
        const sheet = workbook.addWorksheet(title)
        sheetItem = {sheet,title,lastRow:-1}
        sheets.push(sheetItem)
      } 

      sheetItem.sheet.getCell("A1").value = `${type.title} - ${initiative.name}`
      sheetItem.sheet.getCell("A1").font = {
        name: "Roboto",
        size: 14,
        bold: true,
        color: { argb: "FFED7D31" },
      };

      if(sheetItem.lastRow === -1){
        sheetItem.lastRow = 3;
        const titles = initiative.collectors.map(colector => colector.label)
        titles.unshift(t('terms.date'))
        if(unit) {
          titles.unshift(t('terms.unit'))
        }
        for(var idx = 0; idx < titles.length; idx++) {
          const cell = getCell(sheetItem.lastRow,idx)
          sheetItem.sheet.getCell(cell).value = titles[idx];
          sheetItem.sheet.getColumn(idx+1).width = titles[idx].length+2;
          sheetItem.sheet.getCell(cell).font = {
            name: "Calibri",
            size: 11,
            bold: true,
            color: { argb: "FF000000" },
          };

        }
        sheetItem.lastRow = 4;
      }

      const collectors = initiative.collectors.map<{id:string,isNumber:boolean}>(colector => ({id:colector.id, isNumber:colector.input.type === "number" || colector.input.type === "percentage"}))
      collectors.unshift({id:'date',isNumber:false})
      if(unit) {
        collectors.unshift({id:'date',isNumber:false})
      }

      const theData = state.questionnaire?.panel.Relation(initiative)?.flat()
      if(theData) {
        for(const data of theData) {
          for(var idx = 0; idx < collectors.length; idx++) {
            const cell = getCell(sheetItem.lastRow,idx)
            if(collectors[idx].id === '') {
              sheetItem.sheet.getCell(cell).value = unit;
            }
            else {
              var value = data[collectors[idx].id]
              if(collectors[idx].isNumber && isNaN(value as number)) {
                value = 'N/A'
              }
              sheetItem.sheet.getCell(cell).value = value;
            }
          }
          sheetItem.lastRow++
        }
      }
    })

  });
}

