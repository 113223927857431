import { QuestionnaireInitiative } from "@icindex/v3-firebase";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InitiativeDetail } from "./InitiativeDetail";

function TypeView() {
    const {id} = useParams();
    const {state} = useContext(AppContext);
    const [expanded,setExpanded] = useState<{[index:string]:string}>({});
    const iId = parseInt(id!)
    var initiatives = iId == -1 ? state.questionnaire!.powerBiInitiatives : state.questionnaire!.panel.Types()[parseInt(id!)-1].initiatives!

    initiatives = initiatives.filter(x => state.questionnaire!.panel.IsInitiativeAvailable(x))

    const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      const newExpand = {...expanded}
      newExpand[id!] = isExpanded ? panel : "";
      setExpanded(newExpand)
    };

    const accordions = initiatives.map((initiative:QuestionnaireInitiative) => {
        return (
            <Accordion expanded={expanded[id!] === initiative.id} onChange={handleChange(initiative.id)} key={initiative.docPath} >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id={initiative.id} >
                    <Typography>{(initiative as any).typeName? `${(initiative as any).typeName} - `:''}{initiative.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <InitiativeDetail initiative={initiative} baseYear={state.questionnaire!.panel.BaseYear()}/>
                </AccordionDetails>
            </Accordion>
        )
    });

    return (
        <div>
            {accordions}
        </div>
    )
}

export default TypeView