export type DashboardState = {
    selectedType?: string
    selectedMonth: number
}

export enum DashboardStateTypes {
    UPDATETYPE = "UPDATETYPE",
    UPDATEMONTH = "UPDATEMONTH",
}

export type DashboardActions = 
  { type: DashboardStateTypes.UPDATETYPE, id:string}
  | { type: DashboardStateTypes.UPDATEMONTH, idMonth:number}

export const dashboardReducer = (state:DashboardState,action:DashboardActions): DashboardState => {
    switch(action.type) {
        case DashboardStateTypes.UPDATETYPE: 
            return {...state,selectedType: action.id};
        case DashboardStateTypes.UPDATEMONTH:
            return {...state,selectedMonth: action.idMonth};
        default:
            return state;
    }
};
