export default {
    translations: { // Mesmo valor usado nas configurações (defaultNS)
      terms: {
        hello: 'Olá',
        weight: 'Peso',
        indicators: 'Indicadores',
        goal:'Meta',
        goals: 'Metas',
        dashboard: 'Painel',
        period: "Período",        
        strategicdimension: "Pilar Estratégico",
        performance: "Performance",
        performanceinperiod: "Performance nos períodos de medição",
        unit: "Unidade",
        date: 'Data',
        table: 'Tabela',
        graphics: 'Gráficos',
      },
      phrases: {
        yourICIndex: 'Seu IC-Index',
        selectQuestionnaire: 'Selecione processo mensurado',
        allUnits : 'Todas Unidades',
        exportExcel: 'Exportar resultados em Excel',
        exportDataExcel: 'Exportar dados em Excel',
        generalIndicatorResults: 'Resultados Gerais do Indicador',
        saveGraph: 'Salvar Gráfico'
      },
      initials: {
        quarter : 'T',
        months: ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez']
      }
    }
  }