import GaugeChart from 'react-gauge-chart'
import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { getColor } from '../components/TypeResult';
import { GaugeDisplay } from '../components/GaugeDisplay';
import { useTranslation } from 'react-i18next';

function ICIndexBoard() {
    const {state,dispatch} = useContext(AppContext);
    const result = state.questionnaire?.panel.Result()!;
    const {t} = useTranslation();

    return (
        <div id="gaugeArea" className='flex w-full px-8 font-bold text-black bg-white h-fit place-items-center'>
            <div className='flex-1'></div>
            
            <div className='flex-none justify-center text-2xl ml-8'>
                <h1>{t("phrases.yourICIndex")}</h1>
            </div>

            <div className='flex-none border-1 text-black bg-white m-8 justify-center rounded-xl'>
                <GaugeDisplay value={isNaN(result) ? 0 : result} />
            </div>
            
            <div className='flex-1'></div>

        </div>

    )
}

export default ICIndexBoard;

