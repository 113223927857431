import { createContext,Dispatch, FC, useReducer } from "react";
import { DashboardActions, dashboardReducer, DashboardState } from "./DashboardState";
import { QuestionnaireActions, questionnaireReducer, QuestionnaireState } from "./QuestionnaireState";
import { ThemeActions, themeReducer, ThemeState } from "./ThemeState";

export type AppType = {
    questionnaire: QuestionnaireState|null;
    dashboard: DashboardState;
    theme: ThemeState;
}

const initialState:AppType = {
    questionnaire:null,
    dashboard: {
        selectedMonth: new Date().getMonth()
    },
    theme: {
        background: 'bg-indigo',
        grade: {
            low: '#314B89',
            med: '#317289',
            hi: '#478931'
        },
        primary:'#058DF9',
        logo:'logoAcao.png'
    }
};

const AppContext = createContext<{state: AppType;dispatch: Dispatch<QuestionnaireActions|DashboardActions|ThemeActions>,}>({
    state: initialState,
    dispatch: () => null
})

const mainReducer = (data:AppType, action:any):AppType=> ({
    questionnaire: questionnaireReducer(data.questionnaire,action),
    dashboard: dashboardReducer(data.dashboard, action),
    theme: themeReducer(data.theme,action)
})

const AppProvider: FC<any> = ({children}) => {
    const [state,dispatch] = useReducer(mainReducer,initialState);
  
    return (
      <AppContext.Provider value={{state,dispatch}}>
        {children}
      </AppContext.Provider>
    )
}

export {AppContext,AppProvider};