import {Theme} from '@icindex/v3-firebase'

export type ThemeState = Theme;

export enum ThemeStateTypes {
    SET = "SET",
}

export type ThemeActions = 
  { type: ThemeStateTypes.SET, theme:Theme}

export const themeReducer = (state:ThemeState,action:ThemeActions): ThemeState => {
    switch(action.type) {
        case ThemeStateTypes.SET: 
            return {...action.theme};
        default:
            return state;
    }
};
