import { QuestionnaireIndicator, QuestionnaireInitiative } from '@icindex/v3-firebase';
import { Fragment, useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { PercentFormat } from '../components/PercentFormat';
import { useTranslation } from 'react-i18next';
import { makeHeaderTitle } from '../functions/TitleFunctions';

export type InitiativeTableProps = {
    initiative: QuestionnaireInitiative;
}

export function InitiativeTable(props: InitiativeTableProps) {
    
    const { state } = useContext(AppContext);

    const headerClass = `text-white text-xl font-bold ${state.theme.background}-600 p-2`
    const bodyClass = 'border-grey-400 p-2 border-b border-r text-lg'
    const lastBodyClass = `border-grey-400 p-2 border-r text-lg font-bold ${state.theme.background}-200`

    const content = props.initiative.indicators!.map((el: QuestionnaireIndicator) => {
        const period = state.questionnaire!.panel.InitiativePeriod(props.initiative);
        const totals = state.questionnaire?.panel.IndicatorResults(el,period)
        const totalEls = totals!?.map((val:number,idx:number) => (
            <div key={idx} className={idx === totals.length-1 ? bodyClass.replace('border-r','') : bodyClass}>{isNaN(val) ? "N/A" : (<PercentFormat value={val} />)} </div>
        ));

        return (
            <Fragment key={el.id}>
                <div className={`${bodyClass} col-span-4`}> {el.name} </div>
                <div className={bodyClass}> <PercentFormat key={`wiight${el.name}`} value={state.questionnaire!.panel.IndicatorWeight(el)} /> </div>
                {totalEls}
            </Fragment>
        )
    });

    const {t} = useTranslation();
    const results  = state.questionnaire!.panel.InitiativeResults(props.initiative)
    const resultHeaders = results.map((val:number,idx:number) => (
        <div className={headerClass} key={`resultHeader${idx}`}> {makeHeaderTitle(state.questionnaire!.panel,props.initiative,idx,state.questionnaire!.panel.BaseYear(),t)}  </div>
    ));
    const resultFooters = results.map((val:number,idx:number) => (
        <div className={lastBodyClass} key={idx} > <PercentFormat value={val} /> </div>
    ));


    return (
        <div className={`grid grid-cols-${5+results.length} border-grey-400 rounded-md border`} key={`initiativeTable${props.initiative.id}`}>
           <div className={`${headerClass} col-span-4`} key={`initiativeTableIndicators${props.initiative.id}`}> {t("terms.indicators")}</div>
           <div className={headerClass} key={`initiativeTableWeight${props.initiative.id}`}> {t('terms.weight')} </div>
           {resultHeaders}
           {content}
           <div className={`${lastBodyClass} col-span-4`} > Total </div>
           <div className={lastBodyClass} >  </div>
           {resultFooters}
        </div>

    )
}

