export default {
    translations: { // Mesmo valor usado nas configurações (defaultNS)
      terms: {
        hello: 'Hello',
        weight: 'Weight',
        indicators: 'Indicators',
        goal:'Goal',
        goals: 'Goals',
        period: "Period",
        dashboard: 'Dashboard',
        strategicdimension: "Strategic Pilar",        
        performance: "Performance",
        performanceinperiod: "Performance in measured periods",        
        unit: "Unit",
        date: 'Date',
        table: 'Table',
        graphics: 'Charts',
      },
      phrases: {
        yourICIndex: 'Your IC-Index',
        selectQuestionnaire: 'Select measured process',
        allUnits : 'All Units',
        exportExcel: 'Export results to Excel',
        exportDataExcel: 'Export data to Excel',
        generalIndicatorResults: 'Indicator General Results',
        saveGraph: 'Save Chart'
      },
      initials: {
        quarter : 'Q',
        months: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      }
    }
  }