import { QuestionnaireType } from '@icindex/v3-firebase';
import { ReactNode, useContext, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { TypeResult } from '../components/TypeResult';

function TypePanel() {
    const { state, dispatch } = useContext(AppContext);

    const types = state.questionnaire!.panel.Types().filter(x => x.id !== 'powerBI' && (
        state.questionnaire!.panel.CurrentUnit() === undefined || state.questionnaire!.panel.TypeWeight(x) > 0
    )
    );

    const cols = `grid-cols-${(types.length < 4 ? types.length:4)}`;
    return (
        <div className={`grid ${cols} gap-4 content-center bg-white`}>
            {
                types.map((el: QuestionnaireType) => {
                  return (<TypeResult label={el.title!} value={state.questionnaire?.panel.TypeResult(el)!} weight={state.questionnaire!.panel.TypeWeight(el)} id={el.id} key={el.id} />)
                })
            }
        </div>
    )
}

export default TypePanel;

