import { QuestionnaireInfo, QuestionnaireType } from "@icindex/v3-firebase";
import Database from "@icindex/v3-firebase/dist/Database";
import JSONImporter from "@icindex/v3-firebase/dist/JSONImporter";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useEffect, useState } from "react";

export default function ImportJsonWizard(props: {
  done: ()=>void,
  cancel: () => void
  visible:boolean
}) {

  const Step = {
    PASTE: 0,
    CONFIRM: 1,
    CREATING: 2,
  } as const;

  const [state,setState] = useState<number>(Step.PASTE);
  const [visible,setVisible] = useState(false);
  const [content, setContent] = useState('');
  const [data,setData] = useState<{info:QuestionnaireInfo, types:QuestionnaireType[]}>();
  const handleClose = () => {
    setVisible(false)
    setState(Step.PASTE)
    props.cancel()
  }

  useEffect(()=>{
    setContent('')
    setVisible(props.visible)
  },[props])

  const doPaste = () => {
    const dataContent = JSON.parse(content);
    const importer = new JSONImporter('import',dataContent);
    importer.doImport()
      .then(({info, types}) =>{
        setData({info,types});
        setState(Step.CONFIRM)
    }).catch((reason) => {alert(reason);handleClose();})
  }

  const doImport = () => {
    setState(Step.CREATING)
    if(data)
      Database.saveQuestionnaire(data.info,data.types)
        .then(() => {
          setVisible(false);
          setState(Step.PASTE)
          props.done();
        })
        .catch((reason) => {alert(reason);handleClose()})
  };

  const dialogs = [
    {
      step: Step.PASTE,
      dialog: (
        <Dialog open={visible} onClose={handleClose}>
        <DialogTitle>Importar Questionário</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Cole aqui o conteúdo gerado pelo plugin do Excel.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            multiline
            maxRows={6}
            variant="standard"
            value={content}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setContent(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={doPaste}>Continuar</Button>
          <Button onClick={handleClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      )
    },
    {
      step: Step.CONFIRM,
      dialog:(
        <Dialog
        open={visible}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Importar Questionário?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirma importação do questionário <b>{data?.info.name}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancela</Button>
          <Button onClick={doImport} autoFocus>Confirma</Button>
        </DialogActions>
      </Dialog>        
      )

    },
    {
      step: Step.CREATING,
      dialog:(
        <Dialog onClose={handleClose} open={visible}>
          <div className="flex m-8">
            <CircularProgress />          
          </div>
        </Dialog>        
      )
    }
  ]

  return dialogs[state].dialog;
}