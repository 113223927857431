import { useContext, useState } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import { QuestionnaireData, QuestionnaireInitiative } from '@icindex/v3-firebase';
import { AppContext } from '../../context/AppContext';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export type DataDialogProps = {
    initiative: QuestionnaireInitiative;
    data?: QuestionnaireData|null;
    open: boolean;
    callback: (value?: QuestionnaireData) => void;
}

export function DataDialog(props: DataDialogProps) {
    const [fields, setFields] = useState<any>({})
    const [errors,setErrors] = useState<any>({})
    const [date, setDate] = React.useState<Dayjs | null>(null);
    const [collectors,setCollectors] = useState<React.ReactElement[]>([]);
    const [title,setTitle] = useState<string>();

    const {state} = useContext(AppContext);
    React.useEffect(()=> {
        setDate(props.data? dayjs(props.data["date"].toString(), "DD/MM/YYYY") : dayjs());
        const auxFields = {...props.data}
        for (const el of props.initiative.collectors) {
            if(el.input.type === "percentage" && !isNaN(auxFields[el.id] as number)) {
                (auxFields[el.id] as number)*=100
            }
        }

        if(props.data)
            setFields(auxFields)
    },[props])

    const handleCancel = () => {
        props.callback(undefined)
        setFields({})
    }

    const handleConfirm = () => {
        var auxErrors:any = {}
        for (const el of props.initiative.collectors) {
            if(el.input.type === "number" || el.input.type === "percentage") {
                if(isNaN(parseFloat(fields[el.id]))) {
                    console.log('error',el.id)
                    auxErrors[el.id] = true;
                } else {
                    fields[el.id] = parseFloat(fields[el.id])
                    if(el.input.type === "percentage") {
                        fields[el.id] /= 100
                    }
                }
            }
        }
        
        if(Object.keys(auxErrors).length > 0) {
            console.log('error1',auxErrors)
            setErrors(auxErrors)
        }
        else {
            props.callback(
                { ...fields,
                  date: date === null ? date : date.format("DD/MM/YYYY"),
                  docId: props.data?.docId,
                  docPath: props.data?.docPath
                }
            )
            setFields({})
        }
    }

    React.useEffect(()=>{
        let defaultValue: string | number | null
        let title = "Adicionar anotação"
        
        const auxCollectors = [];
        for (const el of props.initiative.collectors) {
            let adornment: any
            const handleChange = (evt: any,type:string) => {
                let value = evt.target.value;
                setFields({
                    ...fields,
                    [el.id]: value
                })
            }
    
            if (Object.keys(fields).length > 0) {
                defaultValue = (fields as any)[el.id] 
                setTitle("Editar anotação");
    
                if (el.input.type === "percentage") {
                    adornment = <InputAdornment position='start'>%</InputAdornment>
                }
            }
            else
                defaultValue = null

            var element = (
                <TextField 
                    error = {errors[el.id] === true}
                    label={el.label}
                    value={defaultValue || ''}
                    onChange={(evt)=>handleChange(evt,el.input.type)}
                    InputProps={{ startAdornment: adornment, inputMode: 'numeric' }}
                    fullWidth />
            )
            if(el.input.type === 'list') {
                if(el.input.values === undefined) console.log(el.id)
                element = (
                    <FormControl className='w-full'>
                        <InputLabel id={el.label}>{el.label}</InputLabel>
                        <Select 
                            labelId={el.label}
                            label={el.label||'Oi'}
                            value={defaultValue}
                            onChange={(evt)=>handleChange(evt,el.input.type)}
                        >
                            {
                                el.input.values.map(x => {
                                    let label,value;
                                    if(typeof x === 'string') {
                                        label = x;
                                        value = x;
                                    } else {
                                        label = x.label
                                        value = x.value
                                    }

                                    return (<MenuItem value={value}>{label}</MenuItem>)
                                })
                            }
                        </Select>
                    </FormControl>
                )
            }

            auxCollectors.push((
                <div className='font-bold p-2 grow' key={el.id}>
                    {element}
                </div>
            ))
        }
        setCollectors(auxCollectors)
    },[fields,errors]);
    

    const period = state.questionnaire!.panel.InitiativePeriod(props.initiative);

    return (
        <Dialog open={props.open} maxWidth="lg" fullWidth={true}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers className='h-4/6 w-full'>
                <div className='grid grid-cols-3'>
                    {basePeriodComponent(period, date, setDate,fields,setFields)}
                    {collectors}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm}>Confirmar</Button>
                <Button onClick={handleCancel}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    );
}

function basePeriodComponent(base_period: string, date: any, setDate: any,fields:any, setFields:any) {
    switch (base_period) {
        case 'month':
            
            return (
                <div className='mt-2'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker className='w-full'
                            views={['day']}
                            label="Escolha uma data"
                            value={date}
                            onChange={(newDate) => {
                                setDate(newDate!);
                            }}
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                    </LocalizationProvider>
                </div>
            )
            break;

        case 'semester':
            const setSemester = (val:string) => {
                setFields({...fields,semester:val})
            }
            return (
                <div className='mt-1 px-4 py-2 border rounded border-grey-400 relative h-14'>
                    <h2 className='absolute -top-4 translate-y-1/2 text-xs bg-white px-2 text-grey-700'>Semestre</h2>
                    <div className='flex flex-row items-center'>
                        <Radio size='small' checked={fields.semester === 'First Semester'} onClick={()=>setSemester("First Semester")}/><span className='-ml-2 mr-2'>1º</span>
                        <Radio size='small' checked={fields.semester === 'Second Semester'} onClick={()=>setSemester("Second Semester")}/><span className='-ml-2'>2º</span>
                    </div>
                </div>
            )
            break;

        case 'quarter':
            const setQuarter = (val:string) => {
                setFields({...fields,quarter:val})
            }
            return (
                <div className='mt-1 px-4 py-2 border rounded border-grey-400 relative h-14'>
                    <h2 className='absolute -top-4 translate-y-1/2 text-xs bg-white px-2 text-grey-700'>Trimestre</h2>
                    <div className='flex flex-row items-center'>
                        <Radio size='small' checked={fields.quarter === 'First Quarter'} onClick={()=>setQuarter("First Quarter")}/><span className='-ml-2 mr-2'>1º</span>
                        <Radio size='small' checked={fields.quarter === 'Second Quarter'} onClick={()=>setQuarter("Second Quarter")}/><span className='-ml-2'>2º</span>
                        <Radio size='small' checked={fields.quarter === 'Third Quarter'} onClick={()=>setQuarter("Third Quarter")}/><span className='-ml-2'>3º</span>
                        <Radio size='small' checked={fields.quarter === 'Fourth Quarter'} onClick={()=>setQuarter("Fourth Quarter")}/><span className='-ml-2'>4º</span>
                    </div>
                </div>
            )
            break;

        default:
            return null
    }
}

